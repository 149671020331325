import { useForwardGeocodeQuery } from "@/api/address";
import { CloseCircleIcon } from "@/icons/core-solid";
import { Box, Input } from "@givenwell/components";
import { GeocodeResult } from "@givenwell/marketplace-api";
import { colors } from "@givenwell/theme";
import { useCombobox } from "downshift";
import { useRef } from "react";

type LocationComboboxProps = {
  country: string;
  value?: string;
  onValueChange?: (value: string) => void;
  placeholder?: string;
  onLocationSelected?: (location: GeocodeResult, query: string) => void;
  onClear?: () => void;
};
export function LocationCombobox(props: LocationComboboxProps) {
  "use no memo";
  const { data: suggestions } = useForwardGeocodeQuery(props.country, props.value?.trim() ?? "");

  const { highlightedIndex, isOpen, getInputProps, getMenuProps, selectItem, closeMenu, setHighlightedIndex } =
    useCombobox({
      onInputValueChange({ inputValue }) {
        props.onValueChange?.(inputValue || "");
      },
      onSelectedItemChange({ selectedItem }) {
        if (selectedItem) {
          props.onLocationSelected?.(selectedItem, props.value ?? "");
          closeMenu();
        }
      },
      inputValue: props.value,
      itemToString: item => item?.place_name ?? "",
      items: suggestions || [],
      onStateChange({ type }) {
        if (type === useCombobox.stateChangeTypes.InputChange) {
          setHighlightedIndex(0);
        }
      },
    });

  const addressInputRef = useRef<HTMLInputElement>(null);
  const inputProps = getInputProps({ placeholder: props.placeholder, ref: addressInputRef });

  function handleClearClick() {
    props.onClear?.();
    addressInputRef.current?.focus();
  }

  return (
    <Box css={{ w: "100%", pos: "relative" }}>
      <Box>
        <Input
          size="lg"
          autoFocus
          rightIcon={
            <CloseCircleIcon
              width="14"
              height="14"
              onClick={handleClearClick}
              style={{ pointerEvents: "all", cursor: "pointer" }}
            />
          }
          {...inputProps}
        />
      </Box>

      <Box
        {...getMenuProps()}
        css={{
          position: "absolute",
          left: 0,
          right: 0,
          backgroundColor: "white",
          shadow: "md",
          maxH: 320,
          zIndex: "10",
          rounded: 8,
          overflow: "hidden",
          d: "flex",
        }}
        style={{
          visibility: isOpen && suggestions?.length ? "visible" : "hidden",
        }}
      >
        <ul
          style={{
            overflow: "auto",
            maxHeight: "100%",
            width: "100%",
            maxWidth: "100%",
          }}
        >
          {isOpen &&
            suggestions?.map((item, index) => (
              <Box
                as="li"
                key={`${index}`}
                onClick={() => selectItem(item)}
                css={{
                  d: "block",
                  py: 8,
                  px: 12,
                  maxW: "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                style={{
                  backgroundColor: index === highlightedIndex ? colors.blue500 : undefined,
                  color: index === highlightedIndex ? "white" : undefined,
                }}
              >
                {item.place_name}
              </Box>
            ))}
        </ul>
      </Box>
    </Box>
  );
}
