import { listingQuery } from "@/api/listings";
import { queryClient } from "@/api/utils";
import { checkIdPrefix } from "@/utils/id";
import { captureCustomEvent } from "@/utils/posthog";
import { createRoute } from "@tanstack/react-router";
import { marketplaceRoute } from "../../+route";

export const listingRoute = createRoute({
  path: "listings/$listingId",
  getParentRoute: () => marketplaceRoute,
  loader: async ({ params: { listingId } }) => {
    checkIdPrefix(listingId, "LISTING");
    const data = await queryClient.ensureQueryData(listingQuery(listingId));
    captureCustomEvent("member_viewed_listing", {
      listing_id: listingId,
      listing_name: data.title,
      supplier_id: data.supplier.id,
      supplier_name: data.supplier.name,
    });
  },
}).lazy(() => import("./+route.lazy").then(m => m.route));
