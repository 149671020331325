import { queryOptions, useQuery } from "@tanstack/react-query";
import { api, queryClient } from "./utils";

function seededShuffle<T>(array: T[], seed: number) {
  let m = array.length;
  let t: T;
  let i: number;

  while (m) {
    i = Math.floor(random(seed) * m--);
    t = array[m]!;
    array[m] = array[i]!;
    array[i] = t;
    ++seed;
  }

  return array;
}

function random(seed: number) {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

const randomSeed = Math.random() * 1000;

export const resourcesHomeQuery = queryOptions({
  queryKey: ["resources", "list"],
  queryFn: async () => {
    const home = await api.resources.getResourcesHome();
    const groups = home.groups || [];
    const shuffledGroups = groups.map(group => {
      const resources = [...(group.resources || [])];
      return {
        ...group,
        resources: seededShuffle(resources, randomSeed),
      };
    });
    return shuffledGroups;
  },
});
export function useResourcesHomeQuery() {
  return useQuery(resourcesHomeQuery);
}
useResourcesHomeQuery.prefetch = () => queryClient.prefetchQuery(resourcesHomeQuery);

const resourceQueryOptions = (resourceId: string | undefined) =>
  queryOptions({
    queryKey: ["resources", "detail", resourceId],
    queryFn: async () => {
      const resource = await api.resources.getResourceForMarketplace({ resourceId: resourceId! });
      return resource;
    },
    enabled: !!resourceId,
  });
export function useResourceQuery(resourceId: string | undefined) {
  return useQuery(resourceQueryOptions(resourceId));
}
useResourceQuery.prefetch = (resourceId: string | undefined) => {
  return queryClient.prefetchQuery(resourceQueryOptions(resourceId));
};
