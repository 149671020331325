import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { array, fallback, object, optional, string } from "valibot";
import { appRoute } from "../+route";

const searchSchema = object({
  q: fallback(optional(string()), undefined),
  category: fallback(optional(array(string())), undefined),
  "location-type": fallback(optional(string()), undefined),
});

export const eapRoute = createRoute({
  path: "eap",
  getParentRoute: () => appRoute,
});

export const eapIndexRoute = createRoute({
  path: "/",
  validateSearch: searchSchema,
  getParentRoute: () => eapRoute,
  component: lazyRouteComponent(() => import("./+EAP")),
});
