import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { appRoute } from "../+route";

const searchSchema = object({
  showReceipt: fallback(optional(string()), undefined),
});

export const messagesRoute = createRoute({
  path: "messages",
  getParentRoute: () => appRoute,
  validateSearch: valibotValidator(searchSchema),
  component: lazyRouteComponent(() => import("./+route.lazy")),
});
