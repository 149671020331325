import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { marketplaceRoute } from "../+route";

export const purchaseRoute = createRoute({
  path: "purchase/$listingId/$variantId",
  getParentRoute: () => marketplaceRoute,
});

const searchSchema = object({
  offer: fallback(
    optional(
      object({
        id: string(),
        time: string(),
      }),
    ),
    undefined,
  ),
});

export const purchaseIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => purchaseRoute,
  component: lazyRouteComponent(() => import("./+route.lazy")),
  validateSearch: valibotValidator(searchSchema),
});
