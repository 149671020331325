import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { email, fallback, object, optional, pipe, string } from "valibot";
import { rootRoute } from "../__root";

const searchSchema = object({
  utm: fallback(optional(string()), undefined),
  from: fallback(optional(string()), undefined),
  email: fallback(optional(pipe(string(), email())), undefined),
});

export const nominateSupplierRoute = createRoute({
  path: "nominate",
  getParentRoute: () => rootRoute,
  validateSearch: valibotValidator(searchSchema),
  component: lazyRouteComponent(() => import("./+route.lazy")),
});
