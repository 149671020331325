import { useSearchRadiusStore } from "@/pages/(app)/marketplace/useSearchRadius";
import { seededShuffle } from "@givenwell/components/src/utils/math";
import { GetHomeControllerListingsResponseGroup } from "@givenwell/marketplace-api";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { api, queryClient } from "./utils";

const makeHomeQueryOptions = (searchRadius: number, type: "marketplace" | "eap") =>
  queryOptions({
    queryKey: ["marketplace-home", { searchRadius, type }],
    queryFn: async () => {
      const results = await api.home.getMarketplaceHome({ searchRadiusMeters: searchRadius, type });
      const shuffledGroups: GetHomeControllerListingsResponseGroup[] = [];
      for (const group of results.groups || []) {
        if (!group.listings.length) continue;

        group.listings = seededShuffle(group.listings);
        shuffledGroups.push(group);
      }
      return shuffledGroups;
    },
  });

export function useMarketplaceHomeQuery(type: "marketplace" | "eap") {
  const searchRadius = useSearchRadiusStore(state => state.searchRadius);
  return useQuery(makeHomeQueryOptions(searchRadius, type));
}
useMarketplaceHomeQuery.prefetch = () => queryClient.prefetchQuery(makeHomeQueryOptions(40000, "marketplace"));
