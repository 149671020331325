import { useUpdateMyLocationMutation } from "@/api/me";
import { api } from "@/api/utils";
import { LocationCombobox } from "@/components/ui/LocationCombobox";
import { LocationTargetIcon } from "@/icons/core-solid";
import { RadiusSlider } from "@/pages/(app)/marketplace/RadiusSlider";
import { useSearchRadiusStore } from "@/pages/(app)/marketplace/useSearchRadius";
import { Box, Center, Description, Field, Flex, Label, Spinner, Text } from "@givenwell/components";
import { GeocodeResult } from "@givenwell/marketplace-api";
import { useMutation } from "@tanstack/react-query";
import type { LngLatLike } from "mapbox-gl";
import { Suspense, lazy, useState } from "react";
import { BottomSheet } from "./ui/BottomSheet";
import { Button } from "./ui/Button";
import { IconButton } from "./ui/IconButton";

const Map = lazy(async () => {
  const { MapWithSearchRadius } = await import("./maps/MapWithSearchRadius");
  return { default: MapWithSearchRadius };
});

async function getCurrentPositionAsync(): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
}

type LatLng = { latitude: number; longitude: number };

type LocationPickerModalProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onLocationChange?: (name: string, coords: LatLng) => void;
  locationName?: string;
  locationCoords?: { lng: number; lat: number };
  countryCode: string;

  dontSave?: boolean;
};
export function LocationPickerModal({
  open,
  onOpenChange,
  onLocationChange,
  dontSave,
  locationName: initialLocationName,
  locationCoords: initialCoords,
  countryCode,
}: LocationPickerModalProps) {
  const [coords, setCoords] = useState<{ lng: number; lat: number } | undefined>(initialCoords);
  const [locationName, setLocationName] = useState(initialLocationName ?? "");

  const saveMutation = useUpdateMyLocationMutation();

  const findMyLocationMutation = useMutation({
    mutationFn: async () => {
      const position = await getCurrentPositionAsync();
      const reverseGeocode = await api.address.reverseGeocode({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      const firstResult = reverseGeocode.results[0];
      if (firstResult) {
        setCoords({ lng: position.coords.longitude, lat: position.coords.latitude });
        setLocationName(firstResult.place_name);
      }
    },
  });

  const setLocationFromCoordsMutation = useMutation({
    mutationFn: async ({ latitude, longitude }: { latitude: number; longitude: number }) => {
      setCoords({ lng: longitude, lat: latitude });
      const reverseGeocode = await api.address.reverseGeocode({
        lat: latitude,
        lng: longitude,
      });
      const firstResult = reverseGeocode.results[0];
      if (firstResult) {
        setLocationName(firstResult.place_name);
      }
    },
  });

  function handleLocationSelected(location: GeocodeResult) {
    const marker: LngLatLike = { lng: location.lat_lng.longitude, lat: location.lat_lng.latitude };
    setCoords(marker);

    setLocationName(location.place_name);
  }

  function handleClear() {
    setCoords(undefined);
    setLocationName("");
  }

  const locationSelected = coords !== undefined;

  const searchRadius = useSearchRadiusStore(state => state.searchRadius);
  const setSearchRadius = useSearchRadiusStore(state => state.setSearchRadius);

  const [localSearchRadius, setLocalSearchRadius] = useState(searchRadius);

  function handleSaveAndClose(closeImmediately = false) {
    if (!coords) return;
    if (initialCoords && initialCoords.lat === coords.lat && initialCoords.lng === coords.lng) {
      onOpenChange(false);
      return;
    }

    if (closeImmediately) {
      onOpenChange(false);
    }
    if (dontSave) {
      onOpenChange(false);
      onLocationChange?.(locationName, {
        latitude: coords.lat,
        longitude: coords.lng,
      });
      return;
    } else {
      saveMutation.mutate(
        {
          coords: {
            latitude: coords.lat,
            longitude: coords.lng,
          },
          place_name: locationName,
        },
        {
          onSuccess() {
            onOpenChange(false);
          },
        },
      );
    }
  }

  function handleOpenChange(open: boolean) {
    if (!open) {
      handleSaveAndClose(true);
    }
    onOpenChange(open);
  }

  return (
    <BottomSheet
      open={open}
      onOpenChange={handleOpenChange}
      title="Location"
      content={
        <>
          <Box css={{ h: 20 }} />
          <Field>
            <Label>Address</Label>
            <LocationCombobox
              value={locationName}
              onValueChange={setLocationName}
              onLocationSelected={handleLocationSelected}
              onClear={handleClear}
              country={countryCode}
              placeholder="Your location"
            />
          </Field>
          <Box css={{ h: 28 }} />
          <Suspense
            fallback={
              <Center css={{ aspectRatio: 4 / 3 }}>
                <Spinner />
              </Center>
            }
          >
            <Box css={{ position: "relative", mx: -20 }}>
              <Map
                countryCode={countryCode}
                location={coords}
                searchRadius={searchRadius}
                onLocationChange={({ lng, lat }) => {
                  setLocationFromCoordsMutation.mutate({ latitude: lat, longitude: lng });
                }}
              />
              {"geolocation" in navigator && (
                <IconButton
                  aria-label="Use your current location"
                  variant="plain"
                  color="neutral"
                  onClick={() => findMyLocationMutation.mutate()}
                  css={{ position: "absolute", top: 10, right: 10, backgroundColor: "white" }}
                >
                  {findMyLocationMutation.isPending ?
                    <Spinner />
                  : <LocationTargetIcon />}
                </IconButton>
              )}
            </Box>
          </Suspense>
          <Box css={{ h: 28 }} />
          <Field>
            <Box>
              <Label>Distance from me</Label>
              <Description>Only show me listings within a specific radius</Description>
            </Box>
            <Flex css={{ gap: 20 }}>
              <RadiusSlider
                value={localSearchRadius / 1000}
                onChange={min => {
                  setLocalSearchRadius(min * 1000);
                }}
                onCommit={min => {
                  setLocalSearchRadius(min * 1000);
                  setSearchRadius(min * 1000);
                }}
              />
              <Text>{localSearchRadius / 1000}km</Text>
            </Flex>
          </Field>
          <Box css={{ h: 20 }} />
        </>
      }
      footer={
        <Button
          onClick={() => {
            handleSaveAndClose();
          }}
          variant="filled"
          css={{ w: "100%", mt: 20 }}
          size="lg"
          color="primary"
          disabled={!locationSelected || !coords}
          loading={saveMutation.isPending}
        >
          Apply
        </Button>
      }
    />
  );
}
