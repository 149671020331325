/* eslint-disable @typescript-eslint/no-empty-object-type */

/**
 * Any user fills in the login form.
 */
type UserLoggedIn = {
  name: "user_logged_in";
  properties: {};
};

/**
 * Any user completes the nominate supplier form.
 */
type UserNominatedSupplier = {
  name: "user_nominated_supplier";
  properties: {};
};

/**
 * Logged in member completes the nominate supplier form.
 */
type MemberNominatedSupplier = {
  name: "member_nominated_supplier";
  properties: {
    member_email: string;
  };
};

/**
 * Member chooses to log out.
 */
type MemberLoggedOut = {
  name: "member_logged_out";
  properties: {};
};

type MemberViewedListing = {
  name: "member_viewed_listing";
  properties: {
    listing_id: string;
    listing_name: string;
    supplier_id: string;
    supplier_name: string;
  };
};

type MemberViewedVariant = {
  name: "member_viewed_variant";
  properties: {
    variant_id: string;
    variant_name: string;
    listing_id: string;
    listing_name: string;
    supplier_id: string;
    supplier_name: string;
  };
};

type MemberLikesListing = {
  name: "member_liked_listing";
  properties: {
    listing_id: string;
    listing_name: string;
    supplier_id: string;
    supplier_name: string;
  };
};
type MemberLikesResource = {
  name: "member_liked_resource";
  properties: {
    resource_id: string;
    resource_name: string;
  };
};

type MemberPlayedAudio = {
  name: "member_played_audio";
  properties: {
    resource_id: string;
    resource_name: string;
  };
};
type MemberPlayedVideo = {
  name: "member_played_video";
  properties: {
    resource_id: string;
    resource_name: string;
  };
};

type MemberSpentTokens = {
  name: "member_spent_tokens";
  properties: {
    balance_before: number;
    amount: number;
    balance_after: number;
  };
};
type MemberPurchasedListing = {
  name: "member_purchased_listing";
  properties: {
    variant_id: string;
    variant_name: string;
    listing_id: string;
    listing_name: string;
    supplier_id: string;
    supplier_name: string;
    token_price: number;
    balance_before: number;
    balance_after: number;
  };
};
type MemberSubmittedClaim = {
  name: "member_submitted_claim";
  properties: {
    vendor_name: string;
    price_cents: number;
    currency: string;
  };
};
type MemberToppedUpTokens = {
  name: "member_topped_up_tokens";
  properties: {};
};
type MemberIntendedTopup = {
  name: "member_intended_topup";
  properties: {
    amount?: number;
    listing_id?: string;
    listing_name?: string;
    variant_id?: string;
    variant_name?: string;
    balance: number;
  };
};

type MemberFilteredByCategory = {
  name: "member_filtered_by_category";
  properties: {
    category_ids: string[];
  };
};
type MemberFilteredByType = {
  name: "member_filtered_by_type";
  properties: {
    type_ids: string[];
  };
};
type MemberFilteredByPrice = {
  name: "member_filtered_by_price";
  properties: {
    min_price: number;
    max_price: number;
  };
};
type MemberFilteredByExperience = {
  name: "member_filtered_by_experience";
  properties: {
    experiences: string[];
  };
};
type MemberFilteredByTheme = {
  name: "member_filtered_by_theme";
  properties: {
    theme_name: string;
  };
};
type MemberIntendedPurchase = {
  name: "member_intended_purchase";
  properties: {
    variant_id: string;
    variant_name: string;
    listing_id: string;
    listing_name: string;
    supplier_id: string;
    supplier_name: string;
    token_price: number;
  };
};

type MemberEnquiredSupplier = {
  name: "member_enquired_supplier";
  properties: {
    supplier_id: string;
    supplier_name: string;
    listing_id?: string;
    listing_name?: string;
    variant_id?: string;
    variant_name?: string;
  };
};

type MemberFoundNoResults = {
  name: "member_found_no_results";
  properties: {
    query: string;
  };
};

type CustomEvent =
  | MemberEnquiredSupplier
  | MemberFilteredByCategory
  | MemberFilteredByType
  | MemberFilteredByPrice
  | MemberFilteredByExperience
  | MemberFilteredByTheme
  | MemberFoundNoResults
  | MemberIntendedTopup
  | MemberIntendedPurchase
  | MemberLikesListing
  | MemberLikesResource
  | MemberLoggedOut
  | MemberNominatedSupplier
  | MemberPlayedAudio
  | MemberPlayedVideo
  | MemberPurchasedListing
  | MemberSpentTokens
  | MemberSubmittedClaim
  | MemberToppedUpTokens
  | MemberViewedListing
  | MemberViewedVariant
  | UserLoggedIn
  | UserNominatedSupplier;

// Copilot, give me a type definition to guarantee my job security:
export async function captureCustomEvent<EventName extends CustomEvent["name"]>(
  event: EventName,
  properties: Record<string, any> extends Extract<CustomEvent, { name: EventName }>["properties"] ?
    Record<string, never>
  : Extract<CustomEvent, { name: EventName }>["properties"],
) {
  const { captureEvent } = await import("@/third-party");
  const hasProperties = Object.keys(properties).length > 0;
  return captureEvent(event, hasProperties ? properties : null);
}
