import { Button } from "@/components/ui/Button";
import { Box, Flex, Img, Text } from "@givenwell/components";
import { keyframes, styled } from "@givenwell/stitches";
import { colors, easing } from "@givenwell/theme";
import { Dialog as BaseDialog } from "radix-ui";
import { ReactNode, useState } from "react";
import confidentialC from "./confidential-C.png";
import footerB from "./footer-B.png";
import footerD from "./footer-D.png";
import forSuppliersHeroMobile from "./for-suppliers-hero-mobile.png";
import motivated from "./motivated-customers.png";
import settingUp1 from "./setting-up-01.png";
import settingUp4 from "./setting-up-04.png";

type Step = {
  featureImg: string;
  title: ReactNode;
  description: ReactNode;
};

const STEPS: Step[] = [
  {
    featureImg: forSuppliersHeroMobile,
    title: "Welcome to Givenwell!",
    description: <Text>You’re all set to start your wellness journey.</Text>,
  },
  {
    featureImg: settingUp4,
    title: "Receive Tokens",
    description: (
      <>
        <Text>
          Your employer will be paying your wellbeing allowance in the form of Givenwell Tokens. You can spend them
          right away or save up for bigger things. Note, tokens expire after 12 months, so don't wait too long to use
          them!
        </Text>
      </>
    ),
  },
  {
    featureImg: motivated,
    title: "Spend in Marketplace",
    description: (
      <Text>
        You can redeem your tokens on a wide range of wellness services and products from the Givenwell Marketplace.
      </Text>
    ),
  },
  {
    featureImg: confidentialC,
    title: "Private & Confidential",
    description: (
      <>
        <Text>
          Givenwell is a safe place to care for yourself. Your employer won't know how you spend your allowance—we keep
          it private.
        </Text>
      </>
    ),
  },
  {
    featureImg: footerB,
    title: "Your Wallet",
    description: (
      <>
        <Text>
          Your wallet is like a bank account, where you can view tokens you’ve spent and received. Access this anytime
          by clicking on the wallet icon in the top right corner.
        </Text>
      </>
    ),
  },
  {
    featureImg: footerD,
    title: "Wellness Resources",
    description: (
      <>
        <Text>
          Your account includes complementary access to our Resources Library of videos, audio and guides covering a
          range of wellbeing topics.
        </Text>
      </>
    ),
  },
  {
    featureImg: settingUp1,
    title: "Youʼre good to go!",
    description: (
      <>
        <Text>If you need support, you can reach us at support@givenwell.com or in the app via the menu.</Text>
      </>
    ),
  },
];

export function FirstTimeDialog({ open, onOpenChange }: { open: boolean; onOpenChange: (open: boolean) => void }) {
  const [stepIdx, setStepIdx] = useState(0);

  const step = STEPS[stepIdx]!;

  return (
    <BaseDialog.Root open={open} onOpenChange={onOpenChange}>
      <BaseDialog.Portal>
        <Overlay />
        <Content>
          <Box css={{ flex: "1 0 0px", overflowY: "auto", display: "flex", flexDirection: "column" }}>
            <Box css={{ flexGrow: 1 }} />
            <Box css={{ p: 16, align: "center" }}>
              <Box css={{ width: "100%", aspectRatio: 16 / 9, position: "relative" }}>
                <Img
                  css={{ position: "absolute", inset: 0, objectFit: "contain", width: "100%", height: "100%" }}
                  src={step.featureImg}
                />
              </Box>
              <Text css={{ fontScale: "3xl", weight: 500, color: colors.blue800 }}>{step.title}</Text>
              <Box css={{ h: 24 }} />
              <Box css={{ color: colors.gray700, maxW: 400, mx: "auto" }}>{step.description}</Box>
            </Box>
            <Box css={{ flexGrow: 1 }} />
          </Box>
          <Pagination pages={STEPS.length} activeIdx={stepIdx} />
          <Footer>
            <Button
              variant="filled"
              color="primary"
              size="lg"
              css={{ width: "100%" }}
              onClick={() => {
                if (stepIdx < STEPS.length - 1) {
                  setStepIdx(stepIdx + 1);
                } else {
                  onOpenChange(false);
                }
              }}
            >
              {stepIdx < STEPS.length - 1 ? "Continue" : "Go to Marketplace"}
            </Button>
            {stepIdx < STEPS.length - 1 && (
              <Button
                variant="outlined"
                color="primary"
                size="lg"
                css={{ width: "100%", mt: 16 }}
                onClick={() => {
                  onOpenChange(false);
                }}
              >
                Skip Tutorial
              </Button>
            )}
          </Footer>
        </Content>
      </BaseDialog.Portal>
    </BaseDialog.Root>
  );
}

const overlayClose = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
});
const Overlay = styled(BaseDialog.Overlay, {
  position: "fixed",
  inset: 0,
  backgroundColor: colors.blue900 + "80",
  zIndex: 2147483647,
  "&[data-state='closed']": {
    animation: `${overlayClose()} 500ms ${easing.standard}`,
  },
});
const contentClose = keyframes({
  from: {
    transform: "translateY(0%)",
  },
  to: {
    transform: "translateY(100%)",
  },
});
const Content = styled(BaseDialog.Content, {
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 2147483647,

  display: "flex",
  flexDir: "column",
  backgroundColor: "white",
  roundedT: 16,
  maxW: 900,
  mx: "auto",
  height: "calc(100% - max(var(--safe-area-inset-top), 10px) - 10px)",
  "&[data-state='closed']": {
    animation: `${contentClose()} 500ms ${easing.standard}`,
  },
});

const Footer = styled("div", {
  padding: 16,
  pb: "max(var(--safe-area-inset-bottom), 16px)",
  boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.04)",
});

function Pagination({ pages, activeIdx }: { pages: number; activeIdx: number }) {
  return (
    <Flex css={{ items: "center", justify: "center", py: 20, gap: 8 }}>
      {Array.from({ length: pages }).map((_, i) => (
        <PageDot key={i} active={activeIdx === i} />
      ))}
    </Flex>
  );
}

const PageDot = styled("div", {
  width: 8,
  height: 8,
  borderRadius: 9999,
  backgroundColor: colors.gray100,

  transitionDuration: "400ms",
  transitionTimingFunction: easing.standard,
  transitionProperty: "background-color, width",

  variants: {
    active: {
      true: {
        backgroundColor: colors.blue500,
        width: 60,
      },
    },
  },
});
