import { useMeQuery } from "@/api/auth";
import { mySupportersQuery } from "@/api/me";
import { auth } from "@/api/utils";
import { useHasUnreads } from "@/hooks/useHasUnreads";
import {
  CameraIcon,
  ChatBubbleTextSquareIcon,
  CogIcon,
  FastfowardClockIcon,
  HeartFilledIcon,
  HelpQuestionIcon,
  LogoutIcon,
  MegaphoneIcon,
  StoreIcon,
  WebcamVideoIcon,
} from "@/icons/core-solid";
import { NewLabel } from "@/pages/(app)/+route";
import { captureCustomEvent } from "@/utils/posthog";
import { config } from "@common/utils/config";
import { Box, Separator } from "@givenwell/components";
import { CSS, keyframes, styled } from "@givenwell/stitches";
import { colors, easing } from "@givenwell/theme";
import { IconHeartHandshake } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { createLink, Link, LinkComponent } from "@tanstack/react-router";
import { Dialog as BaseDialog } from "radix-ui";
import { ComponentPropsWithoutRef, forwardRef, MouseEventHandler, ReactNode } from "react";

export function ModalDesktopSidebar({ open, onOpenChange }: { open: boolean; onOpenChange: (open: boolean) => void }) {
  const onClose = () => onOpenChange(false);

  return (
    <BaseDialog.Root open={open} onOpenChange={onOpenChange}>
      <BaseDialog.Portal>
        <Overlay />
        <Content>
          <DesktopSidebarContent onClose={onClose} />
        </Content>
      </BaseDialog.Portal>
    </BaseDialog.Root>
  );
}

export function PersistentDesktopSidebar() {
  return (
    <PersistentSidebarContent>
      <DesktopSidebarContent onClose={() => {}} />
    </PersistentSidebarContent>
  );
}

function DesktopSidebarContent({ onClose }: { onClose: () => void }) {
  const hasUnreads = useHasUnreads();
  const { data: supporterData } = useQuery(mySupportersQuery());
  const me = useMeQuery();
  const hasEAP = config.features.eap && supporterData?.supporters.some(s => s.eap.enabled);
  return (
    <>
      <NavigationMenuLink to="/marketplace" onClick={onClose} icon={<StoreIcon />} activeIcon={<StoreIcon />}>
        Marketplace
      </NavigationMenuLink>
      <NavigationMenuLink
        to="/messages"
        onClick={onClose}
        icon={<ChatBubbleTextSquareIcon />}
        activeIcon={<ChatBubbleTextSquareIcon />}
        showBadge={hasUnreads}
      >
        Messages
      </NavigationMenuLink>
      <NavigationMenuLink to="/purchases" onClick={onClose} icon={<FastfowardClockIcon />}>
        Purchases
      </NavigationMenuLink>
      <NavigationMenuLink to="/claims" onClick={onClose} icon={<CameraIcon />}>
        Claims
      </NavigationMenuLink>
      {hasEAP && (
        <NavigationMenuLink to="/eap" onClick={onClose} icon={<IconHeartHandshake />}>
          EAP
          <NewLabel inline />
        </NavigationMenuLink>
      )}
      {!!supporterData?.supporters.length && (
        <NavigationMenuLink to="/resources" onClick={onClose} icon={<WebcamVideoIcon />}>
          Resources
        </NavigationMenuLink>
      )}
      <NavigationMenuLink to="/favourites" onClick={onClose} icon={<HeartFilledIcon />}>
        Favourites
      </NavigationMenuLink>
      <Box css={{ px: 8 }}>
        <Separator />
      </Box>
      <NavigationMenuLink
        to="/nominate"
        search={{ from: location.href, email: me.data?.email }}
        icon={<MegaphoneIcon />}
      >
        Nominate
      </NavigationMenuLink>
      <NavigationMenuA href="mailto:support@givenwell.com" icon={<HelpQuestionIcon />}>
        Contact Us
      </NavigationMenuA>
      <Box css={{ flex: "1 0 0px" }} />
      <NavigationMenuLink to="/settings" onClick={onClose} icon={<CogIcon strokeWidth={1.5} />}>
        Settings
      </NavigationMenuLink>
      <NavigationMenuLink
        to="/"
        params={true}
        search={true}
        onClick={e => {
          e.preventDefault();
          captureCustomEvent("member_logged_out", {});
          auth.logout();
        }}
        icon={<LogoutIcon strokeWidth={1.5} />}
      >
        Sign out
      </NavigationMenuLink>
    </>
  );
}

const fadeIn = keyframes({
  from: { opacity: 0, backdropFilter: "blur(0px)" },
  to: { opacity: 1, backdropFilter: "blur(0px)" },
});

const fadeOut = keyframes({
  from: { opacity: 1, backdropFilter: "blur(0px)" },
  to: { opacity: 0, backdropFilter: "blur(0px)" },
});

const slideInFromLeft = keyframes({
  from: { transform: "translateX(-100%)" },
  to: { transform: "translateX(0)" },
});

const slideOutToLeft = keyframes({
  from: { transform: "translateX(0)" },
  to: { transform: "translateX(-100%)" },
});

const Overlay = styled(BaseDialog.Overlay, {
  backgroundColor: colors.blue900 + "80",
  position: "fixed",
  inset: 0,
  "&[data-state=open]": {
    animation: `${fadeIn()} 500ms cubic-bezier(.2,0,0,1) forwards`,
  },
  "&[data-state=closed]": {
    animation: `${fadeOut()} 400ms cubic-bezier(.2,0,0,1) forwards`,
  },
  zIndex: 999,
});

const PersistentSidebarContent = styled("div", {
  position: "fixed",
  bottom: 0,
  w: 220,
  padding: 16,
  pb: "max(16px, var(--safe-area-inset-bottom))",
  // backgroundColor: colors.white,
  color: colors.gray800,
  zIndex: 1000,
  flexDirection: "column",
  gap: 8,

  left: 0,
  top: "calc(77px + var(--safe-area-inset-top))",

  display: "none",
  "@lg": {
    display: "flex",
  },
});

const Content = styled(BaseDialog.Content, {
  position: "fixed",
  bottom: 0,
  w: "calc(100% - 72px)",
  maxW: 220,
  padding: 16,
  pb: "max(16px, var(--safe-area-inset-bottom))",
  backgroundColor: colors.white,
  color: colors.gray800,
  zIndex: 1000,
  display: "flex",
  flexDirection: "column",
  gap: 8,

  left: 0,
  top: "calc(77px + var(--safe-area-inset-top))",
  "&[data-state=open]": {
    animation: `${slideInFromLeft()} 500ms cubic-bezier(.2,0,0,1) forwards`,
  },
  "&[data-state=closed]": {
    animation: `${slideOutToLeft()} 400ms cubic-bezier(.2,0,0,1) forwards`,
  },
});

type NavigationMenuLinkProps = ComponentPropsWithoutRef<"a"> & {
  css?: CSS;
  icon?: ReactNode;
  activeIcon?: ReactNode;
  onClick?: MouseEventHandler<"a">;
  showBadge?: boolean;
};

export const NavigationMenuLink = createLink(
  forwardRef(function NavigationMenuLink({
    css,
    children,
    icon,
    activeIcon,
    showBadge,
    ...props
  }: NavigationMenuLinkProps) {
    return (
      <StyledA {...(props as any)} css={css}>
        {icon && (
          <IconBox>
            <ActiveIcon>{activeIcon || icon}</ActiveIcon>
            <InactiveIcon>{icon}</InactiveIcon>
            {showBadge && <RedDotBadge />}
          </IconBox>
        )}
        {children}
      </StyledA>
    );
  }),
);

function RedDotBadge() {
  return (
    <Box
      css={{
        position: "absolute",
        top: 0,
        right: 0,
        rounded: 10,
        bg: colors.pink,
        boxShadow: `0 0 0 2px ${colors.cream}`,
        minW: 10,
        h: 10,
      }}
    />
  );
}

type NavigationMenuAProps = ComponentPropsWithoutRef<"a"> & {
  css?: CSS;
  icon?: ReactNode;
};

export function NavigationMenuA({ css, children, icon, ...props }: NavigationMenuAProps) {
  return (
    <StyledA {...props} css={css}>
      {icon && <IconBox>{icon}</IconBox>}
      {children}
    </StyledA>
  );
}

const linkCSS: CSS = {
  height: 40,
  display: "flex",
  alignItems: "center",
  px: (40 - 24) / 2,
  gap: (40 - 24) / 2,

  color: colors.gray500,
  fontScale: "sm",
  weight: 500,
  rounded: 8,
  // fontFamily: fonts.ftHabit,
  svg: {
    color: colors.gray500,
  },
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.05)",
  },
  transition: `background-color 0.2s ${easing.standard}`,

  "&.active": {
    weight: 500,
    bg: colors.cyanLight,
    color: colors.navy,
    svg: {
      color: colors.navy,
    },
  },
};

const StyledLink = styled(Link, linkCSS) as unknown as LinkComponent<"a">;
const StyledA = styled("a", linkCSS);

const IconBox = styled("span", {
  display: "flex",
  items: "center",
  justify: "center",
  position: "relative",
  size: 24,

  color: colors.gray500,
  ".active > &": {
    color: colors.navy,
  },
});

const ActiveIcon = styled("span", {
  display: "none",
  "[aria-current=page] &": {
    display: "block",
  },
});
const InactiveIcon = styled("span", {
  "[aria-current=page] &": {
    display: "none",
  },
});
