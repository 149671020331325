import { useConversationsQuery } from "@/api/messages";
import { useInterval } from "@givenwell/hooks";

export function useHasUnreads(refreshSeconds = 20) {
  const conversationsQuery = useConversationsQuery();
  const conversations = conversationsQuery.data ?? [];
  const unreadCount = conversations.filter(c => !c.seen.me).length;

  useInterval(() => conversationsQuery.refetch(), refreshSeconds * 1000);

  return unreadCount > 0;
}
