import { useMarketplaceHomeQuery } from "@/api/home";
import { mySupportersQuery } from "@/api/me";
import { queryClient } from "@/api/utils";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { array, fallback, finite, number, object, optional, pipe, string, toMinValue } from "valibot";
import { marketplaceRoute } from "./+route";

const searchSchema = object({
  q: fallback(optional(string(), ""), ""),
  "location-type": fallback(optional(string(), ""), ""),
  "min-tokens": fallback(optional(pipe(number(), finite(), toMinValue(0)), 0), 0),
  "max-tokens": fallback(optional(pipe(number(), finite(), toMinValue(0)), 0), 5000),
  category: fallback(optional(array(string()), [] as string[]), [] as string[]),
  "variant-type": fallback(optional(array(string()), [] as string[]), [] as string[]),
  "available-from": fallback(optional(string()), undefined),
  "available-before": fallback(optional(string()), undefined),
});
export const marketplaceIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => marketplaceRoute,
  loader: () => {
    queryClient.ensureQueryData(mySupportersQuery());
    useMarketplaceHomeQuery.prefetch();
  },
  validateSearch: valibotValidator(searchSchema),
  component: lazyRouteComponent(() => import("./+index.lazy")),
});
