import { useMeQuery } from "@/api/auth";
import { mySupportersQuery } from "@/api/me";
import { BottomNav, BottomNavItem, BottomNavLink } from "@/components/ui/BottomNav";
import { useHasUnreads } from "@/hooks/useHasUnreads";
import { CameraIcon, ChatBubbleTextSquareIcon, FastfowardClockIcon, MenuIcon, StoreIcon } from "@/icons/core-solid";
import { config } from "@common/utils/config";
import { Box, Flex } from "@givenwell/components";
import { colors } from "@givenwell/theme";
import { IconHeartHandshake } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { createRoute, getRouteApi, Link, Outlet, RegisteredRouter, RouteIds, useMatches } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { lazy, Suspense, useRef, useState } from "react";
import { boolean, fallback, object, optional } from "valibot";
import { authRoute } from "../__root";
import { TopAppBar } from "./TopAppBar";
import { WhatsNewDialog } from "./WhatsNew";

export const appRoute = createRoute({
  id: "app",
  getParentRoute: () => authRoute,
  loader: () => {
    useMeQuery.prefetch();
  },
  validateSearch: valibotValidator(
    object({
      "first-time": fallback(optional(boolean()), undefined),
      fullscreen: fallback(optional(boolean()), undefined),
    }),
  ),
  pendingComponent: PendingAppLayout,
  component: AppLayout,
  notFoundComponent: NotFoundComponent,
});

// ------------------------------------------------------------

const NavDrawer = lazy(() => import("./NavDrawer"));
type RouteId = RouteIds<RegisteredRouter["routeTree"]>;

const routesWithMobileNav: RouteId[] = [
  "/auth/app/marketplace/",
  "/auth/app/messages/",
  "/auth/app/purchases/",
  "/auth/app/resources/",
  "/auth/app/favourites",
  "/auth/app/claims/",
  "/auth/app/eap",
];

// evil
const routesWithMobileNavOnlyOnSmBreakpoint: RouteId[] = ["/auth/app/messages/$conversationId"];

const routesWithPersistentDesktopSidebar: RouteId[] = [
  "/auth/app/marketplace/",
  "/auth/app/messages",
  "/auth/app/purchases/",
  "/auth/app/resources/",
  "/auth/app/favourites",
  "/auth/app/claims",
  "/auth/app/wallet",
  "/auth/app/marketplace/suppliers/$supplierId",
  "/auth/app/eap",
];

const route = getRouteApi("/auth/app");

function AppLayout() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const hasUnreads = useHasUnreads();

  const shouldShowMobileNav = useMatches({
    select(matches) {
      return matches.some(match => routesWithMobileNav.includes(match.routeId));
    },
  });
  const shouldShowMobileNavOnSmBreakpoint = useMatches({
    select(matches) {
      return matches.some(match => routesWithMobileNavOnlyOnSmBreakpoint.includes(match.routeId));
    },
  });
  const sidebarIsPersistent = useMatches({
    select(matches) {
      return matches.some(match => routesWithPersistentDesktopSidebar.includes(match.routeId));
    },
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null!);

  const supporters = useQuery(mySupportersQuery());
  const hasEAP = supporters.data?.supporters.some(supporter => supporter.eap.enabled);

  const { fullscreen } = route.useSearch();

  return (
    <Flex css={{ flexDirection: "column", height: "100%" }}>
      {/* Mobile "Top Bar" */}
      <Box
        data-fullscreen={fullscreen ? "" : undefined}
        css={{
          minHeight: "var(--safe-area-inset-top)",
          backgroundColor: colors.cream,
          zIndex: 1000,
          "@lg": {
            display: "none",
          },
          "&[data-fullscreen]": {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: "transparent",
            backdropFilter: "blur(10px)",
          },
        }}
      />
      {/* Desktop Top bar */}
      <TopAppBar scrollContainerRef={scrollContainerRef} persistentSidebar={sidebarIsPersistent} />
      <Flex
        ref={scrollContainerRef}
        css={{
          flexDir: "column",
          flex: "1 0 0px",
          position: "relative",
          overflowY: "auto",
          "@lg": {
            marginLeft: "var(--sidebar-width)",
          },
        }}
        style={
          {
            "--sidebar-width": sidebarIsPersistent ? "220px" : "0",
          } as any
        }
      >
        <Outlet />
      </Flex>

      <BottomNav
        css={{
          display: shouldShowMobileNav ? "flex" : "none",
          "@sm": {
            display: shouldShowMobileNavOnSmBreakpoint ? "flex" : undefined,
          },
        }}
      >
        <BottomNavLink label="Marketplace" icon={<StoreIcon />} activeIcon={<StoreIcon />} to="/marketplace" />
        <BottomNavLink
          label="Messages"
          icon={<ChatBubbleTextSquareIcon />}
          activeIcon={<ChatBubbleTextSquareIcon />}
          to="/messages"
          badge={
            hasUnreads ?
              <Box
                css={{
                  position: "absolute",
                  top: -3,
                  right: -3,
                  rounded: 10,
                  bg: colors.pink,
                  boxShadow: `0 0 0 2px ${colors.cream}`,
                  minW: "10px",
                  h: "10px",
                }}
              />
            : null
          }
        />
        <BottomNavLink label="Claims" icon={<CameraIcon />} to="/claims" />
        {config.features.eap && hasEAP && (
          <BottomNavLink label="EAP" icon={<IconHeartHandshake />} to="/eap" badge={<NewLabel />} />
        )}
        <BottomNavLink label="Purchases" icon={<FastfowardClockIcon />} to="/purchases" />
        <Suspense fallback={<BottomNavItem label="More" icon={<MenuIcon />} />}>
          <BottomNavItem onClick={() => setDrawerOpen(true)} label="More" icon={<MenuIcon />} />
          <NavDrawer open={drawerOpen} onOpenChange={setDrawerOpen} />
        </Suspense>
      </BottomNav>
      <WhatsNewDialog />
    </Flex>
  );
}

type NavigationMenuLinkProps = { inline?: boolean };
export function NewLabel({ inline }: NavigationMenuLinkProps) {
  return (
    <Box
      css={{
        fontSize: 10,
        background: colors.pink,
        color: colors.navy,
        position: inline ? undefined : "absolute",
        top: -7,
        right: -20,
        px: 4,
        borderRadius: 4,
        weight: 500,
      }}
    >
      New
    </Box>
  );
}

function PendingAppLayout() {
  const shouldShowMobileNav = useMatches({
    select(matches) {
      return matches.some(match => routesWithMobileNav.includes(match.routeId));
    },
  });
  const shouldShowMobileNavOnSmBreakpoint = useMatches({
    select(matches) {
      return matches.some(match => routesWithMobileNavOnlyOnSmBreakpoint.includes(match.routeId));
    },
  });
  const sidebarIsPersistent = useMatches({
    select(matches) {
      return matches.some(match => routesWithPersistentDesktopSidebar.includes(match.routeId));
    },
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null!);

  const { fullscreen } = route.useSearch();

  return (
    <Flex css={{ flexDirection: "column", height: "100%" }}>
      {/* Mobile "Top Bar" */}
      <Box
        data-fullscreen={fullscreen ? "" : undefined}
        css={{
          minHeight: "var(--safe-area-inset-top)",
          backgroundColor: "white",
          zIndex: 1000,
          "@lg": {
            display: "none",
          },
          "&[data-fullscreen]": {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: "transparent",
            backdropFilter: "blur(10px)",
          },
        }}
      />
      {/* Desktop Top bar */}
      <TopAppBar scrollContainerRef={scrollContainerRef} persistentSidebar={sidebarIsPersistent} />
      <Flex
        ref={scrollContainerRef}
        css={{
          flexDir: "column",
          flex: "1 0 0px",
          position: "relative",
          overflowY: "auto",
        }}
      >
        <Outlet />
      </Flex>

      <BottomNav
        css={{
          display: shouldShowMobileNav ? "flex" : "none",
          "@sm": {
            display: shouldShowMobileNavOnSmBreakpoint ? "flex" : undefined,
          },
        }}
      >
        <BottomNavLink label="Marketplace" icon={<StoreIcon />} activeIcon={<StoreIcon />} to="/marketplace" />
        <BottomNavLink
          label="Messages"
          icon={<ChatBubbleTextSquareIcon />}
          activeIcon={<ChatBubbleTextSquareIcon />}
          to="/messages"
        />
        <BottomNavLink label="Purchases" icon={<FastfowardClockIcon />} to="/purchases" />
        <BottomNavLink label="Claims" icon={<CameraIcon />} to="/claims" />
        <BottomNavItem label="More" icon={<MenuIcon />} />
      </BottomNav>
    </Flex>
  );
}

function NotFoundComponent() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
