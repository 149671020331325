import { getUserId, useMeQuery } from "@/api/auth";
import { myWalletQueryOptions } from "@/api/me";
import { infiniteTransactionsQueryOptions } from "@/api/transactions";
import { queryClient } from "@/api/utils";
import { captureCustomEvent } from "@/utils/posthog";
import { createRoute, redirect } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { topUpRoute } from "../+route";

const REFETCH_AFTER_TOPUP_DELAY = 3000;

const walletSearchSchema = object({
  payment_intent: fallback(optional(string()), undefined),
  payment_intent_client_secret: fallback(optional(string()), undefined),
  redirect_status: fallback(optional(string()), undefined),
});

/**
 * Literally all this does is wait a bit and then send you wherever you need to go.
 */
export const topUpCompleteRoute = createRoute({
  path: "complete",
  getParentRoute: () => topUpRoute,
  validateSearch: valibotValidator(walletSearchSchema),
  beforeLoad: async ({ search }) => {
    captureCustomEvent("member_topped_up_tokens", {});
    function redirectAppropriately() {
      const returnTo = search["return-to"];

      if (returnTo) {
        throw redirect({
          to: returnTo as any,
        });
      } else {
        throw redirect({
          to: "/wallet",
        });
      }
    }

    // Get user ID for refetching
    await useMeQuery.prefetch();
    const userId = getUserId();

    // Sleep an arbitrary amount of time, could alteratively poll the wallet until the topup is complete
    await sleep(REFETCH_AFTER_TOPUP_DELAY);

    // Refetch wallet
    await queryClient.refetchQueries(myWalletQueryOptions);
    await queryClient.refetchQueries(infiniteTransactionsQueryOptions(userId));
    redirectAppropriately();
  },
});

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
